// All screen views for the AccountLoginRegistration component
export const SCREENS = {
  START: 'start',
  LOGIN_PASSWORD: 'loginPassword',
  CREATE_ACCOUNT: 'createAccount',
  SOCIAL_REGISTRATION: 'socialRegistration',
  VERIFY_OTC: 'verifyOneTimeCode',
  LOGIN_OTC: 'loginWithOneTimeCode',
  USER_NAME: 'userName',
  REGISTRATION_SUCCESS: 'registrationSuccess',
  LOGIN_SUCCESS: 'loginSuccess',
  NBCU_PROFILE: 'nbcuProfile',
};

// Screens that show the form screens even after a user is set as authenticated
export const POST_LOGIN_SCREEN = [
  SCREENS.LOGIN_PASSWORD,
  SCREENS.CREATE_ACCOUNT,
  SCREENS.VERIFY_OTC,
  SCREENS.USER_NAME,
  SCREENS.REGISTRATION_SUCCESS,
  SCREENS.SOCIAL_REGISTRATION,
];

// Screens that display the back button with their corresponding target screens
export const SHOW_BACK_BUTTON = {
  [SCREENS.LOGIN_PASSWORD]: SCREENS.START,
  [SCREENS.CREATE_ACCOUNT]: SCREENS.START,
  [SCREENS.LOGIN_OTC]: SCREENS.LOGIN_PASSWORD,
  [SCREENS.REGISTRATION_SUCCESS]: SCREENS.USER_NAME,
  [SCREENS.NBCU_PROFILE]: SCREENS.START,
};
