import PropTypes from 'prop-types';
import markupAndEmbeds from './markupAndEmbeds';

/**
 * @typedef {object} FactCheckContent
 * @property {object} analysis - Analysis content
 * @property {object} citations - Citations content
 * @property {object} verdict - Verdict content
 */

/**
 * @typedef {object} DateType
 * @property {string} createdAt - Creation date
 * @property {string} modifiedAt - Modification date
 * @property {string} publishedAt - Publication date
 */

/**
 * LiveBlogCardType
 * @typedef {object} LiveBlogCardType
 * @property {Array} authors - Array of authors
 * @property {object} content - Content of the live blog card
 * @property {DateType} date - Date information
 * @property {string} date.createdAt - Creation date
 * @property {string} date.modifiedAt - Modification date
 * @property {string} date.publishedAt - Publication date
 * @property {object} headline - Headline of the live blog card
 * @property {string} id - ID of the live blog card
 * @property {object} primaryImage - Primary image of the live blog card
 * @property {string} path - Path of the live blog card
 * @property {string} reportingFrom - Reporting location
 * @property {FactCheckContent} factCheckContent - Fact check content
 */

// eslint-disable-next-line import/no-default-export
export default {
  authors: PropTypes.arrayOf(PropTypes.shape({})),
  content: PropTypes.shape({}),
  date: PropTypes.shape({
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    publishedAt: PropTypes.string,
  }),
  headline: PropTypes.shape({}),
  id: PropTypes.string,
  primaryImage: PropTypes.shape({}),
  path: PropTypes.string,
  reportingFrom: PropTypes.string,
  factCheckContent: PropTypes.shape({
    analysis: markupAndEmbeds,
    citations: markupAndEmbeds,
    verdict: markupAndEmbeds,
  }),
};
