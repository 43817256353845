const { verticalSiteConfigs } = require('./verticalSiteConfigs');

const brands = [
  'eonline',
  'news',
  'today',
  'msnbc',
  'telemundo',
];

const newsVerticals = [
  'mach',
  'better',
  'think',
  'globalcitizen',
  'select',
];

const telemundoVerticals = [
  'noticias',
  'deportes',
  'localstationdeportes',
  'shows',
  'entretenimiento',
];

const verticalNameMap = {
  better: 'BETTER',
  eonline: 'E! Online',
  globalcitizen: 'Global Citizen',
  mach: 'MACH',
  msnbc: 'MSNBC',
  news: 'NBC News',
  select: 'Select',
  think: 'THINK',
  today: 'TODAY',
  noticias: 'Noticias Telemundo',
  deportes: 'Deportes Telemundo',
  localstationdeportes: 'Deportes Telemundo',
  shows: 'Shows Telemundo',
  entretenimiento: 'Entretenimiento Telemundo',
  telemundo: 'Telemundo',
};

const verticalSlugMap = {
  better: 'better',
  eonline: 'eonline',
  globalcitizen: 'global-citizen',
  mach: 'mach',
  msnbc: 'msnbc',
  news: 'news',
  select: 'select',
  think: 'think',
  today: 'today',
  noticias: 'noticias',
  telemundo: 'telemundo',
};

const verticalToPath = {
  better: 'nbcnews.com/better',
  globalcitizen: 'nbcnews.com/globalcitizen',
  knowyourvalue: 'nbcnews.com/know-your-value',
  msnbc: 'msnbc.com',
  news: 'nbcnews.com',
  select: 'nbcnews.com/select',
  think: 'nbcnews.com/think',
  today: 'today.com',
};

const verticalHfsIdentifierMap = {
  select: 'select_news',
};

const publisherNameMap = {
  today: 'today',
  msnbc: 'msnbc',
  news: 'nbcnews',
  select: 'nbcnews',
  better: 'nbcnews',
  globalcitizen: 'nbcnews',
  think: 'nbcnews',
  mach: 'nbcnews',
  telemundo: 'telemundo',
  noticias: 'telemundo',
  deportes: 'telemundo',
  shows: 'telemundo',
  entretenimiento: 'telemundo',
};

const verticals = [
  ...brands,
  ...newsVerticals,
  ...telemundoVerticals,
];


const capitalize = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;

const capitalizedVerticals = new Set([...newsVerticals, ...telemundoVerticals].map(capitalize));

const publicoHeadlineBrands = ['think', 'today'];

const isIn = (v, verticalList) => verticalList.indexOf(v) !== -1;

const isNotIn = (v, verticalList) => verticalList.indexOf(v) === -1;

const isMain = (v) => brands.indexOf(v) !== -1;

const isSub = (v) => newsVerticals.indexOf(v) !== -1;

/**
 *
 * @param {VerticalType} vertical
 * @returns {string}
 */
const getDisplayNameForVertical = (vertical) => (verticalNameMap[vertical] || '');

const getSlugForVertical = (vertical) => (verticalSlugMap[vertical] || vertical);

/**
 * Provides the correct HFS identifier for a vertical name
 * @param {string} vertical The vertical name to use to look up the HFS identifier
 * @returns {string}
 */
const getHfsIdentifierForVertical = (vertical) => (verticalHfsIdentifierMap[vertical] || vertical);

const hasPublisherRestriction = (publisher, vertical) => {
  if (publisher && (
    // Only show MSNBC content on MSNBC
    (publisher !== 'msnbc' && vertical === 'msnbc')
    // Do not show MSNBC content on other domains
    || (publisher === 'msnbc' && vertical !== 'msnbc')
  )) {
    return true;
  }
  return false;
};

/**
 * @param  {String} vertical
 * @returns  {boolean}
 * @deprecated - See https://nbcnewsdigital.atlassian.net/wiki/spaces/BWT/pages/1263829057/Feature+Flags+Env+Vars+and+You
 */
const isTelemundoVertical = (vertical) => [...telemundoVerticals, 'telemundo'].includes(vertical);

/**
 * @param  {String} vertical
 * @returns  {boolean}
 * @deprecated - See https://nbcnewsdigital.atlassian.net/wiki/spaces/BWT/pages/1263829057/Feature+Flags+Env+Vars+and+You
 */
const isNewsVertical = (vertical) => [...newsVerticals, 'news'].includes(vertical);

const getSiteConfigs = (vertical) => {
  // Properties with their own configurations
  if (verticalSiteConfigs[vertical]) {
    return verticalSiteConfigs[vertical];
  }
  // Telemundo verticals share Telemundo configuration
  if (['entretenimiento', 'shows'].includes(vertical)) {
    return verticalSiteConfigs.telemundo;
  }
  // Default to News
  return verticalSiteConfigs.news;
};

module.exports = {
  brands,
  capitalizedVerticals,
  getDisplayNameForVertical,
  getHfsIdentifierForVertical,
  getSiteConfigs,
  getSlugForVertical,
  hasPublisherRestriction,
  isIn,
  isMain,
  isNewsVertical,
  isNotIn,
  isSub,
  isTelemundoVertical,
  newsVerticals,
  publicoHeadlineBrands,
  publisherNameMap,
  telemundoVerticals,
  verticalNameMap,
  verticals,
  verticalSlugMap,
  verticalToPath,
};
