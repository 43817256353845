import React from 'react';

/**
 * SVG eye icon to use in password field to display password as text
 * @returns {React.ReactElement} The rendered component.
 */
const ShowEye = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.75 10C1.75 10 4.75 4 10 4C15.25 4 18.25 10 18.25 10C18.25 10 15.25 16 10 16C4.75 16 1.75 10 1.75 10Z" stroke="#555555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 12.25C11.2426 12.25 12.25 11.2426 12.25 10C12.25 8.75736 11.2426 7.75 10 7.75C8.75736 7.75 7.75 8.75736 7.75 10C7.75 11.2426 8.75736 12.25 10 12.25Z" stroke="#555555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export { ShowEye };
