import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import BTE from './BTE';

import { shouldRenderRegularBlog } from './liveBlog';
import { transformWidgetData } from './transformWidgetData';
import { getRamenBentoAPIUrl } from './getRamenBentoAPIUrl';

export const CARD_QUERY_LIMITS = {
  FIRST_PAGE: 150,
  FIRST_PAGE_TWITTER: 15,
  LOAD_MORE: 10,
};

const FIRST_PAGE_SIZE = CARD_QUERY_LIMITS.FIRST_PAGE;

dayjs.extend(utc);

/**
 * Handles the article view event by triggering the 'articleInView' event
 * with the ID of the first article if the event is intersecting.
 *
 * @param {IntersectionObserverEntry[]} event - The intersection observer entries.
 * @param {object} props - The properties object.
 * @param {object[]} [props.articles] - The array of articles.
 * @param {object} [props.articles[].id] - The ID of the first article.
 */
export const articleViewHandler = (event, props) => {
  if (event?.[0]?.isIntersecting) {
    const id = props?.articles?.[0]?.id;
    BTE.trigger('articleInView', id);
  }
};

/**
 * Maps the state of the blog to the component's props.
 *
 * @param {object} state - The state object.
 * @param {object} state.article - The article state.
 * @param {object} state.liveBlog - The live blog state.
 * @param {object} state.router - The router state.
 * @returns {object} - The mapped props.
*/
export const blogMapStateToProps = ({ article, liveBlog, router }) => ({
  flag: article?.content?.[0]?.flag ?? null,
  activeItems: liveBlog.activeItems,
  path: router.path,
  domain: router.domain,
  fetchingLatest: liveBlog.fetchingLatest,
  numberOfNewItems: liveBlog.numberOfNewItems,
  loadingMoreCards: liveBlog.loadingMoreCards,
  trueTotalItems: liveBlog.trueTotalItems,
  isRegularBlog: shouldRenderRegularBlog(article?.content?.[0]?.subType ?? null),
});

/**
 * Returns the formatted date published from the given card object.
 *
 * @param {object} card - The card object containing the date information.
 * @param {object} card.date - The date object within the card.
 * @param {string} card.date.publishedAt - The published date in ISO format.
 * @returns {string|undefined} - The formatted date published in UTC, or undefined if the card is not provided.
 */
export const getDatePublished = (card) => {
  let datePublished;
  if (card) {
    const { date: { publishedAt } } = card;
    datePublished = dayjs(publishedAt).utc().format();
  }
  return datePublished;
};


/**
 * Splits the markup and embeds of a card into visible and hidden sections based on the presence of a "SHOW_MORE_BUTTON".
 *
 * @param {object} card - The card object containing content.
 * @param {object} card.content - The content object within the card.
 * @param {Array} card.content.markupAndEmbeds - The array of markup and embed objects.
 * @returns {object} - The card content with bifurcated markup and embeds.
 */
export const getShowMoreBifurcatedMarkupEmbeds = (card) => {
  let visibleMarkupAndEmbeds = card.content.markupAndEmbeds;
  let hiddenMarkupAndEmbeds = [];

  const showMoreBreakpointIndex = card.content.markupAndEmbeds.findIndex(
    (markupAndEmbed) => (markupAndEmbed.widget?.properties?.embed?.type === 'SHOW_MORE_BUTTON' && markupAndEmbed.widget.properties.embed.displayShowMore),
  );
  const hasShowMoreBreakpoint = showMoreBreakpointIndex > -1;


  if (hasShowMoreBreakpoint) {
    visibleMarkupAndEmbeds = card.content.markupAndEmbeds.slice(
      0,
      showMoreBreakpointIndex + 1,
    );
    hiddenMarkupAndEmbeds = card.content.markupAndEmbeds.slice(
      showMoreBreakpointIndex + 1,
    );
  }
  return {
    ...card.content,
    visibleMarkupAndEmbeds,
    hiddenMarkupAndEmbeds,
    hasShowMoreBreakpoint,
  };
};

// exported for testing
/**
 * Overrides the widget presentation configuration with default values.
 *
 * @type {object}
 * @property {string} size - The size of the widget.
 * @property {string} style - The style of the widget.
 * @property {boolean} _overriddenByGetProcessedCardData - Indicates if the presentation values were overridden by getProcessedCardData.
 */
export const widgetPresentationOverride = {
  size: 'medium',
  style: 'default',
  // i'm assuming the original `_override` and `_overrideSource` properties here were for making it
  // known to devs who may be confused where these presentation values were coming from (since
  // theoretically with these overrides the values from API would be different). leaving one here
  // but removing the filename one
  _overriddenByGetProcessedCardData: true,
};

/**
 * Processes the card data by transforming widget data and overriding presentation configurations.
 *
 * @param {object} item - The card item to be processed.
 * @param {object} item.content - The content object within the card.
 * @param {Array} item.content.markupAndEmbeds - The array of markup and embed objects.
 * @param {object} item.headline - The headline object within the card.
 * @param {Array} [item.replies] - The array of replies to the card.
 * @returns {object} - The processed card data with transformed widget data and overridden presentation configurations.
 */
export const getProcessedCardData = (item) => {
  const processed = item;
  const embeds = processed?.content?.markupAndEmbeds ?? [];

  // if headline or content null, default to empty data structure to allow for graceful rendering.
  processed.content = processed.content || { markupAndEmbeds: [], text: [] };
  processed.headline = processed.headline || {};

  processed.content.markupAndEmbeds = embeds.filter((embed) => !!embed)
    .map((embed) => {
      const element = embed;

      if (element.presentation) {
        // Override widget presentation configuration
        element.presentation = {
          ...element.presentation,
          ...widgetPresentationOverride,
        };
      } else if (element.type === 'markup' && element.element !== 'p') {
        // Non-paragraph elements do not have presentation
        // We will add our overrides anyway so components can choose to use
        element.presentation = widgetPresentationOverride;
      }

      return transformWidgetData(element);
    });

  if (processed.replies) {
    processed?.replies.map(getProcessedCardData);
  }

  return processed;
};

/**
 * Formats the URL path for fetching card data based on the provided parameters.
 *
 * @param {object} params - The parameters for formatting the URL path.
 * @param {string} [params.datePublished] - The published date of the article in ISO format.
 * @param {number} params.queryLimit - The limit on the number of query results.
 * @param {string} params.articleId - The ID of the article.
 * @returns {string} - The formatted URL path for fetching card data.
 */
export function formatCardDataUrlPath({
  datePublished,
  queryLimit,
  articleId,
}) {
  const queryParams = new URLSearchParams({ size: queryLimit, articleId });

  if (datePublished) {
    queryParams.set('datePublished', datePublished.replace(/\+(\d){2}:(\d){2}$/, ''));
  }

  return `articlecards/search?${queryParams}`;
}

/**
 * Formats the full URL for fetching article cards data based on the provided arguments.
 *
 * @param {object} formatArgs - The arguments for formatting the URL.
 * @param {string} [formatArgs.datePublished] - The published date of the article in ISO format.
 * @param {number} formatArgs.queryLimit - The limit on the number of query results.
 * @param {string} formatArgs.articleId - The ID of the article.
 * @returns {string} - The formatted full URL for fetching article cards data.
 */
export function formatArticleCardsDataUrl(formatArgs) {
  const path = formatCardDataUrlPath(formatArgs);
  const bentoAPIRoute = getRamenBentoAPIUrl();
  return `${bentoAPIRoute}/${path}`;
}

/**
 * Processes the article cards search data by transforming each card item.
 *
 * @param {object} json - The JSON response containing article cards data.
 * @param {object} json.data - The data object within the JSON response.
 * @param {object} json.data.article - The article object within the data.
 * @param {object} json.data.article.cards - The cards object within the article.
 * @param {Array} json.data.article.cards.items - The array of card items.
 * @returns {object} - The processed article data with transformed card items.
 */
export function processArticleCardsSearchData(json) {
  const rawCards = json?.data?.article?.cards?.items;
  if (Array.isArray(rawCards)) {
    return {
      ...json?.data.article,
      cards: {
        items: rawCards.map((item) => getProcessedCardData(item)),
      },
    };
  }
  return {
    ...json?.data?.article,
    cards: {
      items: [],
    },
  };
}

/**
 * Fetches live blog cards from the given URL and processes the card data.
 *
 * @param {string} url - The URL to fetch the live blog cards from.
 * @param {Function} [processCardData=processArticleCardsSearchData] - The function to process the fetched card data.
 * @returns {Promise<object>} - A promise that resolves to the processed card data.
 * @throws {Error} - Throws an error if the response is not ok.
 */
export function fetchLiveBlogCards(
  url,
  processCardData = processArticleCardsSearchData,
) {
  return fetch(url)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Error fetching live blog cards: ${res.status} ${res.statusText} ${res.url}`);
      }

      return res.json();
    })
    .then(processCardData);
}

/**
 * Fetches a card by its ID and processes the card data.
 *
 * @param {string} id - The ID of the card to fetch.
 * @returns {Promise<object>} - A promise that resolves to the processed card data.
 * @throws {Error} - Throws an error if the response is not ok.
 */
export function fetchCardByID(id) {
  const bentoAPIRoute = getRamenBentoAPIUrl();
  return fetch(`${bentoAPIRoute}/card/${id}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Error fetching card with ID ${id}: ${res.status} ${res.statusText} ${res.url}`);
      }
      return res.json();
    })
    .then((json) => json?.data?.card)
    .then((card) => getProcessedCardData(card));
}

/**
 * Fetches the latest live blog cards by article ID with the specified query limit and date published.
 *
 * @param {object} params - The parameters for fetching the latest live blog cards.
 * @param {number} [params.queryLimit=FIRST_PAGE_SIZE] - The limit on the number of query results.
 * @param {string} [params.articleId] - The ID of the article.
 * @param {string} [params.datePublished] - The published date of the article in ISO format.
 * @returns {Promise<object>} - A promise that resolves to the processed card data.
 */
export function fetchLatestLiveBlogCardsByArticleId({
  queryLimit = FIRST_PAGE_SIZE,
  articleId,
  datePublished,
}) {
  const endpoint = formatArticleCardsDataUrl({
    queryLimit,
    articleId,
    datePublished,
  });

  return fetchLiveBlogCards(endpoint);
}
