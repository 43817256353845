import { useVertical } from 'lib/Hooks/useVertical';
import { useGeoLocation } from 'lib/Hooks/useGeoLocation';

import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { GATED_CONTENT } from 'lib/brandFeatures';

import { AUTHENTICATED } from 'lib/myNewsConstants';
import { getAuthenticationState, isBedrockApiEnabled } from 'lib/authenticationUtils';
import { useBedrockRegistration } from 'store';

/**
 * Determines if the content should be gated based on its type and properties.
 * @param {object} content - The content object to check for gating.
 * @returns {boolean} - Returns true if the content is gated, false otherwise.
 */
function isContentGated(content) {
  switch (content?.type) {
    case 'recipe': {
      // Should gate none Ecommerce Recipes
      const { ecommerceEnabled } = content;
      return !ecommerceEnabled;
    }
    default: {
      // All other content types should use taxonomy to determine if gated
      const { taxonomy: { additionalTerms } = {} } = content;
      return Boolean(additionalTerms?.find((term) => term.slug === 'gated-content'));
    }
  }
}

/**
 * Determines if the content should be gated based on authentication state, vertical, and geolocation.
 * @param {object} content - The content object to check for gating.
 * @returns {boolean} - Returns true if the content is accessible, false otherwise.
 */
export function useGateAccess(content) {
  const showGate = useBedrockRegistration((state) => state.showGate);
  const authenticationState = getAuthenticationState();
  const { vertical } = useVertical();
  const { isUsa } = useGeoLocation();

  const hasGatedContentFeature = getFeatureConfigForBrand(
    GATED_CONTENT,
    vertical,
  );

  const isGated = isContentGated(content);
  const hideGate = isBedrockApiEnabled()
    ? !showGate : authenticationState === AUTHENTICATED;

  /* Gated Feature is not enabled */
  if (!hasGatedContentFeature || !isGated) return true;

  return hideGate && isUsa;
}
