import React from 'react';

/**
 * SVG crossed out eye icon to use in password field to hide password
 * @returns {React.ReactElement} The rendered component.
 */
const HideEye = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.25754 2.25749C2.66759 1.84744 3.33241 1.84744 3.74246 2.25749L17.7425 16.2575C18.1525 16.6675 18.1525 17.3324 17.7425 17.7424C17.3324 18.1525 16.6676 18.1525 16.2575 17.7424L2.25754 3.74241C1.84749 3.33236 1.84749 2.66754 2.25754 2.25749Z" fill="#555555" />
    <path d="M6.76694 9.66603C6.75574 9.77581 6.75 9.88721 6.75 9.99995C6.75 11.7948 8.20506 13.25 10 13.25C10.1127 13.25 10.2241 13.2442 10.3339 13.233L6.76694 9.66603Z" fill="#555555" />
    <path d="M9.66608 6.7669C9.77586 6.75569 9.88726 6.74995 10 6.74995C11.7949 6.74995 13.25 8.20502 13.25 9.99995C13.25 10.1127 13.2443 10.2241 13.2331 10.3339L9.66608 6.7669Z" fill="#555555" />
    <path d="M15.2918 12.3926L16.7063 13.8071C16.7459 13.766 16.7848 13.7249 16.8233 13.6839C17.5775 12.8794 18.1526 12.0786 18.5393 11.48C18.7332 11.1797 18.8814 10.9277 18.9824 10.748L19.1444 10.4472C19.2852 10.1656 19.2852 9.83426 19.1444 9.55274L18.9824 9.25188C18.8814 9.07222 18.7332 8.82022 18.5393 8.51993C18.1526 7.92126 17.5775 7.12055 16.8233 6.31601C15.333 4.72639 13.0245 2.99995 10 2.99995C8.75806 2.99995 7.63684 3.29105 6.64343 3.74425L8.18696 5.28778C8.75628 5.10485 9.36129 4.99995 10 4.99995C12.2255 4.99995 14.042 6.27351 15.3642 7.68389C16.0162 8.37935 16.5193 9.07864 16.8592 9.60498C16.9544 9.75234 17.0362 9.88535 17.1045 9.99995C17.0362 10.1145 16.9544 10.2476 16.8592 10.3949C16.5193 10.9213 16.0162 11.6205 15.3642 12.316C15.3402 12.3416 15.3161 12.3671 15.2918 12.3926Z" fill="#555555" />
    <path d="M3.29367 6.19276C3.25414 6.23391 3.21515 6.27501 3.17671 6.31601C2.42246 7.12055 1.84738 7.92126 1.46074 8.51993C1.2668 8.82022 1.11864 9.07222 1.01758 9.25188L0.855573 9.55274C0.714809 9.83426 0.714809 10.1656 0.855573 10.4472L1.01758 10.748C1.11864 10.9277 1.2668 11.1797 1.46074 11.48C1.84738 12.0786 2.42246 12.8794 3.17671 13.6839C4.66698 15.2735 6.97549 17 10 17C11.2419 17 12.3632 16.7088 13.3566 16.2557L11.813 14.7121C11.2437 14.8951 10.6387 15 10 15C7.77451 15 5.95802 13.7264 4.63579 12.316C3.98379 11.6205 3.48075 10.9213 3.14082 10.3949C3.04565 10.2476 2.96377 10.1145 2.89549 9.99995C2.96377 9.88535 3.04565 9.75234 3.14082 9.60498C3.48075 9.07864 3.98379 8.37935 4.63579 7.68389C4.65977 7.65831 4.68391 7.63278 4.70821 7.6073L3.29367 6.19276Z" fill="#555555" />
  </svg>
);

export { HideEye };
