import PropTypes from 'prop-types';
import content from './content';

/**
 * @typedef {object} PackageType
 * @property {object} autofill - Autofill info
 * @property {string} autofill.type - autofill type
 * @property {string} autofill.value - Value of autofill
 * @property {string} id - Unique identifier
 * @property {Array<import('./content').Content>} items - Array of content items in package
 * @property {object} metadata - Package level metadata
 * @property {string} name - the name of the package
 * @property {subType} string - Subtype of package if selected
 * @property {string} type - type of package (package name)
 * @property {number} zone - zone that package is currently located in
 */
export default PropTypes.shape({
  autofill: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  }),
  id: PropTypes.string,
  items: PropTypes.arrayOf(content),
  metadata: PropTypes.objectOf(PropTypes.shape({})),
  name: PropTypes.string,
  subType: PropTypes.string,
  type: PropTypes.string,
  zone: PropTypes.number,
});
