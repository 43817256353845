import PropTypes from 'prop-types';

import person from './person';

// Author is now an array of embedded persons.

/**
 * AuthorType
 *
 * @typedef {object} AuthorType
 * @property {string} type - Type of the author
 * @property {string} authorType - Type of the author (comes from an enum)
 * @property {object} featuredAuthor - Featured author details
 * @property {boolean} featuredAuthor.isFeaturedAuthor - Whether the author is featured
 * @property {boolean} featuredAuthor.overrideTeaseImage - Whether to override the tease image
 * @property {object} person - Person details
 */
export default PropTypes.shape({
  type: PropTypes.string,
  authorType: PropTypes.string, // comes from an enum
  featuredAuthor: PropTypes.shape({
    isFeaturedAuthor: PropTypes.bool,
    overrideTeaseImage: PropTypes.bool,
  }),
  person,
});
