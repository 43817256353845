import {
  OFFER_OUT_OF_STOCK as OUT_OF_STOCK,
  OFFER_AVAILABLE as AVAILABLE,
  OFFER_UNAVAILABLE as UNAVAILABLE,
} from 'lib/constants';

/**
 * Takes a product offer object and returns the list and sale prices.
 *
 * @param  {Object} offer
 * @returns {{}|{list, sale}}
 */
const getProductOfferPrices = (offer) => {
  if (!(offer?.prices)) return {};

  return offer.prices.reduce((accum, current) => ({
    ...accum,
    [current.type.toLowerCase()]: current.price,
  }),
  {});
};

const getProductOfferId = (offer = {}) => {
  const { unencodedExternalUrl = '' } = offer;
  const [, productId] = unencodedExternalUrl
    ?.match(/^https?:\/\/(?:www\.)?target\.com\/.*-(\d+).*/i) || [];

  return productId;
};

/**
 * Takes a product offer object and returns the list price, sale price, and offer state.
 *
 * @param {object} offer
 * @returns {{}|{list, sale, state}}
 */
const getProductOfferPricesAndAvailability = (offer) => {
  if (!(offer?.prices)) return {};

  return {
    ...getProductOfferPrices(offer),
    state: offer.state,
  };
};

/**
 * Takes a product object, finds the primary offer, and returns the primary product's list price,
 * sale price, and offer state.
 *
 * @param  {Object} product
 * @returns {{}|{list, sale, state}}
 */
const getPrimaryProductOfferPricesAndAvailability = (product) => {
  const productOffers = product?.offers;
  const productItemOffers = product?.item?.offers;
  const offers = productOffers || productItemOffers || [];
  const primaryOffer = offers.find((offer) => offer.isPrimaryOffer);

  return (primaryOffer ? { ...getProductOfferPricesAndAvailability(primaryOffer) } : {});
};

/**
 * Removes spaces and commas from the price string, then returns a decimal.
 *
 * @param  {String} price
 * @returns number|null
 */
const parsePrice = (price) => {
  if (!price) return null;

  return parseFloat(price.replace(/[ ,]/g, ''));
};

/**
 * Returns true if the product offer is on sale, otherwise returns false.
 *
 * @param  {String|number} salePrice
 * @param {String} listPrice
 * @returns boolean
 */
const isOnSale = ({ salePrice, listPrice }) => {
  let sale = salePrice;
  let list = listPrice;
  if (typeof sale === 'string') sale = parsePrice(sale);
  if (typeof list === 'string') list = parsePrice(list);
  return !!sale && (sale < list);
};

function getProductOffers(item) {
  const { offers = [] } = item || {};
  // Ensure out of stock offers appear last
  const inStockOffers = offers.filter((offer) => offer?.state === AVAILABLE);
  const unavailableOffers = offers.filter((offer) => offer?.state === UNAVAILABLE);
  const outOfStockOffers = offers.filter((offer) => offer?.state === OUT_OF_STOCK);
  // Needed to catch products with a null state
  // due to SOVRN pricing updates not being turned on yet
  const nullStateOffers = offers.filter((offer) => offer?.state === null);
  return [...inStockOffers, ...unavailableOffers, ...nullStateOffers, ...outOfStockOffers];
}

function getProductOfferIdsFromProducts(items) {
  return items
    .filter((i) => i)
    .map((i) => {
      const productOffers = getProductOffers(i.item);
      const [productId] = productOffers.map(getProductOfferId).filter((id) => id);
      return productId;
    })
    .filter((id) => Boolean(id));
}

export {
  getProductOfferPrices,
  getProductOfferPricesAndAvailability,
  getPrimaryProductOfferPricesAndAvailability,
  isOnSale,
  parsePrice,
  getProductOffers,
  getProductOfferId,
  getProductOfferIdsFromProducts,
};
