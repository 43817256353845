import i18next from 'i18next';

const overrideLanguageForVertical = {
  noticias: 'es',
  deportes: 'es',
  localstationdeportes: 'es',
  entretenimiento: 'es',
  telemundo: 'es',
  shows: 'es',
};

export function setLanguageForVertical(vertical) {
  // Language defaults to 'en'
  const lng = overrideLanguageForVertical[vertical] || 'en';
  i18next.changeLanguage(lng);
}
