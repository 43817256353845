import { useMyNewsStore, useBedrockRegistration } from 'store';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { BEDROCK_API_ENABLED } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { useVertical } from 'lib/Hooks/useVertical';

/**
 * Checks if the Bedrock API is enabled.
 * @returns {boolean} True if the Bedrock API is enabled, otherwise false.
 */
export function isBedrockApiEnabled() {
  const { vertical } = useVertical();
  const { 'account-login': ldFeatureFlag } = useFeatureFlagContext();
  const brandFeatureFlag = getFeatureConfigForBrand(
    BEDROCK_API_ENABLED,
    vertical,
  );
  return ldFeatureFlag && brandFeatureFlag;
}

/**
 * Gets the current authentication state.
 * @returns {string} The current authentication state.
 */
export function getAuthenticationState() {
  if (isBedrockApiEnabled()) {
    return useBedrockRegistration((state) => state.authenticationState);
  }
  return useMyNewsStore((state) => state.authenticationState);
}

/**
 * Launches the authentication modal.
 * @returns {Function} The function to launch the authentication modal.
 */
export function launchAuthenticationModal() {
  if (isBedrockApiEnabled()) {
    return useBedrockRegistration((state) => state.setAuthenticationModal);
  }
  return useMyNewsStore((state) => state.authenticate);
}

/**
 * Gets the first name of the user.
 * @returns {string} The first name of the user.
 */
export function getFirstName() {
  if (isBedrockApiEnabled()) {
    return useBedrockRegistration((state) => state.user?.first_name);
  }
  return useMyNewsStore((state) => state.user?.givenName);
}

/**
 * Gets the current loading state.
 * @returns {boolean} The current loading state.
 */
export function getLoading() {
  if (isBedrockApiEnabled()) {
    return useBedrockRegistration((state) => state.loading);
  }
  return useMyNewsStore((state) => state.loading);
}

/**
 * Signs out the current user.
 * @returns {void}
 */
export function userSignout() {
  if (isBedrockApiEnabled()) {
    return useBedrockRegistration((state) => state.userSignout);
  }
  return window.HFSapi.identity.unauthenticate;
}


/**
 * Retrieves the customer ID based on the API configuration.
 * @returns {string | undefined} The customer ID if available, otherwise undefined.
 */
export function getCustomerId() {
  if (isBedrockApiEnabled()) {
    return useBedrockRegistration((state) => state.user?.user_id);
  }
  return useMyNewsStore((state) => state.customer?.customerId);
}
