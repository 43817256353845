import React from 'react';
import cx from 'classnames';

import { children as ChildrenPropType } from 'lib/CustomPropTypes';

import styles from './styles.module.scss';

/**
 * LandingPageWrap wrapper component around authentication AccountLoginRegistration component
 * @param {object} props React props
 * @param {object} [props.children] React children for content of AccountLoginRegistration component
 * @returns {React.ReactElement} The rendered component.
 */
const LandingPageWrap = ({ children }) => (
  <div data-testid="account-auth-landing-page" className={cx('account--auth--landing-page', styles.container)}>
    <div className={styles.screenContent}>
      <h2 className={styles.title}>Sign up for your free TODAY account!</h2>
      <div className={styles.wrapperLandingPage}>
        <div className={styles.info}>
          <h3>Enjoy these direct perks when you sign up today for a free TODAY account.</h3>
          <ul className={styles.perks}>
            <li className={styles.perk}>
              <span className="icon icon-recipe" />
              <span>
                <strong>Access all of our recipes</strong>
                      , exclusive content, games and more.
              </span>
            </li>
            <li className={styles.perk}>
              <span className="icon icon-shopping" />
              <span>
                <strong>Get exclusive discounts </strong>
                      and promo codes for all your favorite brands and products.
              </span>
            </li>
            <li className={styles.perk}>
              <span className="icon icon-alert" />
              <span>
                <strong>Stay up to date on the latest </strong>
                      from the TODAY show with our top newsletters.
              </span>
            </li>
            <li className={styles.perk}>
              <span className="icon icon-save-filled" />
              <span>
                <strong>Quickly access and save your favorite recipes</strong>
                      , articles, and workout tips.
              </span>
            </li>
            <li className={styles.perk}>
              <span className="icon icon-user" />
              <span>
                      See all of your favorite content in one place on your
                <strong> personalized dashboard.</strong>
              </span>
            </li>
          </ul>
        </div>
        { children }
      </div>
    </div>
  </div>
);

LandingPageWrap.propTypes = {
  children: ChildrenPropType,
};

export { LandingPageWrap };
