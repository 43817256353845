import PropTypes from 'prop-types';

import author from './author';
import image from './image';
import organization from './organization';
import slide from './slide';
import taxonomy from './taxonomy';
import { videoProps } from './video';

/**
 * @typedef {object} Content
 * @property {string} id - The ID of the content.
 * @property {string} type - The type of the content.
 * @property {object} item - The item details.
 * @property {object} item.videoProps - The content properties.
 * @property {object} item.taxonomy - The taxonomy details.
 * @property {Array} item.authors - The authors of the item.
 * @property {object} item.source - The source details.
 * @property {string} item.source.organization - The organization of the source.
 * @property {Array} item.slides - The slides in the slideshow.
 * @property {string} item.datePublished - The date the item was published.
 * @property {string} item.dateModified - The date the item was modified.
 * @property {string} item.dateCreated - The date the item was created.
 * @property {boolean} item.breakingNews - Flag indicating if the item is breaking news.
 * @property {boolean} item.nativeAd - Flag indicating if the item is a native ad.
 * @property {object} item.urls - The URLs related to the item.
 * @property {string} item.urls.canonical - The canonical URL of the item.
 * @property {string} item.urls.slug - The slug of the item.
 * @property {object} computedValues - The computed values.
 * @property {object} computedValues.teaseImage - The tease image.
 * @property {string} computedValues.headline - The headline of the item.
 * @property {string} computedValues.description - The description of the item.
 * @property {object} computedValues.unibrow - The unibrow details.
 * @property {string} computedValues.unibrow.text - The text of the unibrow.
 * @property {object} computedValues.unibrow.url - The URL of the unibrow.
 * @property {string} computedValues.unibrow.url.primary - The primary URL of the unibrow.
 * @property {string} computedValues.url - The URL of the item.
 * @property {object} metadata - The metadata of the item.
 * @property {number} packageIndex - The index of the package.
 */
export default PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  item: PropTypes.shape({
    /* Type-specific parameters */
    // Video
    ...videoProps,

    // Article
    taxonomy,
    authors: PropTypes.arrayOf(author),
    source: PropTypes.shape({
      organization,
    }),

    // Slideshow
    slides: PropTypes.arrayOf(slide),

    /* Shared parameters */
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
    dateCreated: PropTypes.string,
    breakingNews: PropTypes.bool,
    nativeAd: PropTypes.bool,
    urls: PropTypes.shape({
      canonical: PropTypes.string,
      slug: PropTypes.string,
    }),
  }),
  computedValues: PropTypes.shape({
    teaseImage: image,
    headline: PropTypes.string,
    description: PropTypes.string,
    unibrow: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.shape({
        primary: PropTypes.string,
      }),
    }),
    url: PropTypes.string,
  }),
  metadata: PropTypes.objectOf(PropTypes.shape({})),
  packageIndex: PropTypes.number,
});
