import React from 'react';
import styles from './styles.module.scss';
import NBCUniLogo from './LogosSVG/NBCUniLogo.svg';
import StartTODAYLogo from './LogosSVG/StartTODAYLogo.svg';


/**
 * PoweredByNBCUProfile component displays the NBC Universal logos for TODAY and text.
 */
const PoweredByNBCUProfile = () => (
  <div data-testid="powered-by-nbcu-profile" className={styles.container}>
    <img className={styles.NBCUniLogoStyles} src={NBCUniLogo} alt="NBC Universal Logo" />
    <p className={styles.subHeaderText}>We&apos;re always adding new content and brands.</p>
    <p className={`${styles.subHeaderText} ${styles.subHeaderTextMargin}`}>Check back for more!</p>
    <div className={styles.rowsLogos}>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/agt-small-color.svg" alt="AGT Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/bravo-small-black.svg" alt="Bravo Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.ENews_style} src="https://id.nbcuni.com/kmpsdk/assets/brands/e-news.png" alt="ENews Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/nbc-small-color.svg" alt="NBC Logo" /></div>
    </div>
    <div className={styles.rowsLogos}>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/news-small-color.svg" alt="News Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.NBCOlympics_style} src="https://id.nbcuni.com/kmpsdk/assets/brands/olympics-small-color.svg" alt="Olympics Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/sports-small-black.svg" alt="Sports Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/oxygen-small-color.svg" alt="Oxygen Logo" /></div>
    </div>
    <div className={styles.rowsLogos}>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/pcca-logo-black.png" alt="PCCA Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/syfy-small-black.svg" alt="Syfy Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/telemundo-small-color.svg" alt="Telemundo Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/the-voice.png" alt="The Voice Logo" /></div>
    </div>
    <div className={styles.rowsLogos}>
      <div className={styles.circlesForLogos}><img className={styles.StartToday_style} src={StartTODAYLogo} alt="Start TODAY Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/today-small-color.svg" alt="Today Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/universo.png" alt="Universo Logo" /></div>
      <div className={styles.circlesForLogos}><img className={styles.logoImages} src="https://id.nbcuni.com/kmpsdk/assets/brands/usa-small-color.svg" alt="USA Logo" /></div>
    </div>
  </div>
);

export { PoweredByNBCUProfile };
