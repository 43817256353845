import React from 'react';
import { AppleLoginButton } from './AppleLogin/index';
import { GoogleLoginButton } from './GoogleLogin/index';

import styles from './styles.module.scss';

/**
 * Displays Apple and Google login buttons
 * @param {object} props - The component props.
 *  @param {Function} props.track - The tracking function.
 * @returns {React.ReactElement} The rendered component.
 */
const SocialLogin = ({ track }) => (
  <div data-testid="social-login" className={styles.socialLogin}>
    <GoogleLoginButton track={track} />
    <AppleLoginButton track={track} />
  </div>
);

export { SocialLogin };
