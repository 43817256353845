import PropTypes from 'prop-types';

import headlineProps from './headline';
import urlProps from './url';
import imageProps from './image';
import authorProps from './author';
import taxonomyCollection from './taxonomyCollection';
import organization from './organization';
import description from './description';

export default PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.string,
  subType: PropTypes.string,
  primaryMedia: imageProps,
  headline: headlineProps,
  description,
  url: urlProps,
  taxonomy: taxonomyCollection,
  primaryImage: imageProps,
  socialImage: imageProps,
  teaseImage: imageProps,
  authors: PropTypes.arrayOf(authorProps),
  ecommerceEnabled: PropTypes.bool,
  sponsoredBy: PropTypes.string,
  adsEnabled: PropTypes.bool,
  hidden: PropTypes.bool,
  nativeAd: PropTypes.bool,
  breakingNews: PropTypes.bool,
  body: PropTypes.arrayOf(PropTypes.shape({})),
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  dateCreated: PropTypes.string,
  source: organization,
});
