import React, { useEffect } from 'react';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import { useVertical } from 'lib/Hooks';
import getConfig from 'next/config';
import { decodeJWT } from 'store/utils';

import styles from './styles.module.scss';

const {
  publicRuntimeConfig: {
    TODAY_APPLE_CLIENT_ID,
  },
} = getConfig();

/**
 * Renders a Login with Apple Button
 * @param {object} props - The component props.
 * @param {Function} props.track - The tracking function.
 */
const AppleLoginButton = ({ track }) => {
  const { vertical } = useVertical();
  const socialLogin = useBedrockRegistration((state) => state.socialLogin);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const currentScreen = useBedrockRegistration((state) => state.screen);
  const searchUserByEmail = useBedrockRegistration((state) => state.searchUserByEmail);

  /**
   * Handles successful Apple sign-on response.
   * @param {object} event - The event object from Apple sign-on.
   */
  const signOnSuccess = async (event) => {
    // Handle successful response.
    // eslint-disable-next-line camelcase
    const { id_token } = event.detail.authorization;
    // user data is available only on first sign-in
    // first name may not be available if user hides their email or removes their name in apple modal
    const firstName = event.detail.user?.name?.firstName;
    // get email from token response and check if user has an account
    const { email } = decodeJWT(id_token);
    const res = await searchUserByEmail(email, 'today');
    const isNewUser = !res?.success;

    const status = await socialLogin({ idToken: id_token, firstName, isNewUser }, vertical, 'apple');
    if (status) setScreen(status);
    const payload = {
      action: 'SignIn:Success',
      method: `${currentScreen}:SignUpModal:Apple`,
    };
    track(payload);
  };
  /**
   * Tracks Apple sign-in.
   */
  const trackSignIn = () => {
    const payload = {
      action: 'SignIn:Start',
      method: `${currentScreen}:SignUpModal:Apple`,
    };
    track(payload);
  };

  /**
   * Handles Apple sign-on failure.
   */
  const signOnFailure = () => {
    console.error('There was an error logging in with Apple');
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;
    /**
     * Handles Apple SDK script load
     */
    script.onload = () => {
      // Initialize the Apple Sign-In button
      window.AppleID.auth.init({
        clientId: TODAY_APPLE_CLIENT_ID,
        scope: 'name email',
        redirectURI: `${window.location.origin}/account`,
        state: 'state',
        nonce: 'nonce',
        usePopup: true,
      });
    };

    /**
     * Handles Apple SDK script loading error
     */
    script.onerror = () => {
      console.error('Failed to load Apple ID SDK script.');
      signOnFailure();
    };

    document.body.appendChild(script);

    document.addEventListener('AppleIDSignInOnSuccess', signOnSuccess);
    // Listen for authorization failures.
    document.addEventListener('AppleIDSignInOnFailure', signOnFailure);

    return () => {
      window.removeEventListener('AppleIDSignInOnSuccess', signOnSuccess);
      window.removeEventListener('AppleIDSignInOnFailure', signOnFailure);
    };
  }, []);

  return (
    <div
      id="appleid-signin"
      data-testid="apple-login-btn"
      data-mode="center-align"
      data-type="continue"
      data-color="white"
      data-border="false"
      data-border-radius="8"
      data-width="280"
      data-height="40"
      className={styles.appleSignIn}
      role="toolbar"
      onClick={trackSignIn}
      onKeyDown={(e) => {
        if (e.code === 'Enter' || e.code === 'Space') {
          e.preventDefault();
          trackSignIn();
        }
      }}
    />
  );
};

export { AppleLoginButton };
