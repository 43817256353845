import React from 'react';

import Breakpoints from 'lib/Breakpoints';

const defaultBreakpointState = {
  isS: false,
  isM: false,
  isL: false,
  isXL: true,
  isXXL: false,
  isXXXL: false,
  isSorM: false,
  isLorXL: true,
  // starts as `false` since viewport is only known on the client side after `useEffect` is fired
  hasCheckedViewportSize: false,
};

export const BreakpointContext = React.createContext(defaultBreakpointState);

export const BreakpointContextProvider = ({ children }) => {
  const [breakpointState, setBreakpointState] = React.useState(defaultBreakpointState);

  const breakpointChangeListener = React.useCallback(() => {
    setBreakpointState({
      isS: Breakpoints.isS(),
      isM: Breakpoints.isM(),
      isL: Breakpoints.isL(),
      isXL: Breakpoints.isXL(),
      isXXL: Breakpoints.isXXL(),
      isXXXL: Breakpoints.isXXXL(),
      isSorM: Breakpoints.isSorM(),
      isLorXL: Breakpoints.isLorXL(),
      hasCheckedViewportSize: true,
    });
  }, [Breakpoints]);

  React.useEffect(() => {
    Breakpoints.listenToAll(breakpointChangeListener);

    return () => {
      Breakpoints.removeFromAll(breakpointChangeListener);
    };
  }, [Breakpoints, breakpointChangeListener]);

  // set the breakpoint state once on initial "mount", no deps needed
  React.useEffect(() => {
    setBreakpointState({
      isS: Breakpoints.isS(),
      isM: Breakpoints.isM(),
      isL: Breakpoints.isL(),
      isXL: Breakpoints.isXL(),
      isXXL: Breakpoints.isXXL(),
      isXXXL: Breakpoints.isXXXL(),
      isSorM: Breakpoints.isSorM(),
      isLorXL: Breakpoints.isLorXL(),
      hasCheckedViewportSize: true,
    });
  }, []);

  return (
    <BreakpointContext.Provider value={breakpointState}>
      {children}
    </BreakpointContext.Provider>
  );
};

BreakpointContextProvider.propTypes = {
};
