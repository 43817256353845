import getConfig from 'next/config';

import { stripTrailingSlash } from './urlUtils';

/**
 * Returns the server runtime config value for API_URL_BENTO on the server side (when
 * `serverRuntimeConfig`) is defined, and the client side value on the client side (when
 * `serverRuntimeConfig`) is <b>not</b> defined. This is specifically the URL Ramen uses on the
 * server side to hit itself for data, and on the client side to hit the Ramen server. E.g., in
 * production, this URL will most likely be `localhost:3000`, and on the client side this will most
 * likely be `https://www.nbcnews.com` (or the specific domain for the site a user is on).
 * @returns {string} API_URL_BENTO_SERVER value on the server side, otherwise API_URL_BENTO_PUBLIC
 * (on the client side)
 */
export function getRamenBentoAPIUrl() {
  const {
    serverRuntimeConfig: {
      API_URL_BENTO_SERVER,
    },
    publicRuntimeConfig: {
      API_URL_BENTO_PUBLIC,
    },
  } = getConfig();

  return stripTrailingSlash(API_URL_BENTO_SERVER || API_URL_BENTO_PUBLIC);
}
