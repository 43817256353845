import PropTypes from 'prop-types';

import socialMediaProfiles from './socialMediaProfiles';
import url from './url';

/*
  this was extracted from authors. Needed for author type from image which
  is not embedded.
*/

/**
 * Person Type
 * @typedef {object} PersonType
 * @property {Array} bio - Array of bio objects
 * @property {string} email - Email of the person
 * @property {string} id - ID of the person
 * @property {Array} jobTitle - Array of job titles
 * @property {string} name - Name of the person (required)
 * @property {object} primaryImage - Primary image details
 * @property {string} primaryImage.altText - Alt text for the image
 * @property {string} primaryImage.caption - Caption for the image
 * @property {string} primaryImage.encodingFormat - Encoding format of the image
 * @property {boolean} primaryImage.graphicContent - Whether the image has graphic content
 * @property {number} primaryImage.height - Height of the image
 * @property {string} primaryImage.id - ID of the image
 * @property {string} primaryImage.url - URL of the image
 * @property {number} primaryImage.width - Width of the image
 * @property {string} shortBio - Short bio of the person
 * @property {Array} socialMediaProfiles - Array of social media profiles
 * @property {string} type - Type of the person
 */

export default PropTypes.shape({
  bio: PropTypes.arrayOf(PropTypes.shape({})),
  email: PropTypes.string,
  id: PropTypes.string,
  jobTitle: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  primaryImage: PropTypes.shape({
    altText: PropTypes.string,
    caption: PropTypes.string,
    encodingFormat: PropTypes.string,
    graphicContent: PropTypes.bool,
    height: PropTypes.number,
    id: PropTypes.string,
    url,
    width: PropTypes.number,
  }),
  shortBio: PropTypes.string,
  socialMediaProfiles,
  type: PropTypes.string,
});
