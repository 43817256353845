const OFFER_OUT_OF_STOCK = 'OUT_OF_STOCK';
const OFFER_AVAILABLE = 'AVAILABLE';
const OFFER_UNAVAILABLE = 'UNAVAILABLE';
const SHOP_LIST_BY_ONE_PRODUCT = 'shopListByOneProduct';
const PRODUCT_BACON = 'oneOneProduct';

const BETTER = 'better';
const ENTRETENIMIENTO = 'entretenimiento';
const EONLINE = 'eonline';
const GLOBALCITIZEN = 'globalcitizen';
const MSNBC = 'msnbc';
const NEWS = 'news';
const NOTICIAS = 'noticias';
const SELECT_CNBC = 'select_cnbc';
const SELECT_NEWS = 'select_news';
const SHOWS = 'shows';
const TELEMUNDO = 'telemundo';
const THINK = 'think';
const TODAY = 'today';

const SHARE_LINK_COPIED_TEXT = 'Share link copied';
const LINK_COPIED_TEXT = 'Copied';
const EMBED_COPIED_TEXT = 'Copied';


const BASE_URL = 'https://nodeassets.nbcnews.com/assets/fonts/shared';
const FONTFACE_DEFAULT = `${BASE_URL}/fontface.4381183aa8657c4e016eb0d9f4457556.css`;
const FONTFACE_OPENSANS = `${BASE_URL}/fontface-opensans.2acd4a3b0407ee2e6ba07ca3b90257a4.css`;
const FONTFACE_LATO = `${BASE_URL}/fontface-lato.57dd3ddc846785d510606888c358734c.css`;
const FONTFACE_TODAY = `${BASE_URL}/fontface-today.b7c3f1186929665bcb168452d476f666.css`;
const FONTFACE_OSWALD = `${BASE_URL}/fontface-oswald.e89a49cf6fec000beadbf7dbcc3ba9f2.css`;


const FONTFACE_BY_VERTICAL = {
  eonline: [FONTFACE_DEFAULT, FONTFACE_OPENSANS],
  select: [FONTFACE_LATO],
  today: [FONTFACE_TODAY],
  default: [FONTFACE_DEFAULT, FONTFACE_OSWALD],
};

const JW_PLAYER_LIBRARY_URL = 'https://nodeassets.nbcnews.com/jwplayer/jwplayer-8.30.1/jwplayer.js';


const ALLOWED_USER_REGISTRATION_FIELDS = [
  'email',
  'password',
  'brand',
  'source',
  'profile',
  'first_name',
  'last_name',
];

const ALLOWED_PROFILE_FIELDS = [
  'last_name',
  'first_name',
  'birth_year',
  'gender',
  'address_line1',
  'address_line2',
  'state',
  'zipcode',
  'phone_number',
];

module.exports = {
  OFFER_OUT_OF_STOCK,
  OFFER_AVAILABLE,
  OFFER_UNAVAILABLE,
  SHOP_LIST_BY_ONE_PRODUCT,
  PRODUCT_BACON,
  BETTER,
  ENTRETENIMIENTO,
  EONLINE,
  GLOBALCITIZEN,
  MSNBC,
  NEWS,
  NOTICIAS,
  SELECT_CNBC,
  SELECT_NEWS,
  SHOWS,
  TELEMUNDO,
  THINK,
  TODAY,
  SHARE_LINK_COPIED_TEXT,
  LINK_COPIED_TEXT,
  EMBED_COPIED_TEXT,
  FONTFACE_DEFAULT,
  FONTFACE_OPENSANS,
  FONTFACE_LATO,
  FONTFACE_TODAY,
  FONTFACE_OSWALD,
  FONTFACE_BY_VERTICAL,
  JW_PLAYER_LIBRARY_URL,
  ALLOWED_USER_REGISTRATION_FIELDS,
  ALLOWED_PROFILE_FIELDS,
};
