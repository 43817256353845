import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

/**
 * ButtonBack component
 * @param {object} props - The component props.
 * @param {Function} props.onClick - The click handler.
 * @param {boolean} [props.isLandingPage] - To check whether the layout is Landing Page
 */
export function ButtonBack({ onClick, isLandingPage }) {
  return (
    <button
      data-testid="button-back"
      aria-label="Back"
      type="button"
      onClick={onClick}
      className={cx(styles.back, styles.backButton)}
    >
      {isLandingPage ? (
        <>
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.94599 1.125L1.53401 5.53695C1.40403 5.65773 1.40403 5.85903 1.53401 5.97982L5.94599 10.3918" stroke="#2A2A2A" strokeWidth="1.28571" strokeMiterlimit="10" strokeLinecap="round" />
          </svg>
          <span className={styles.backText}>Back</span>
        </>
      ) : (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3411 5.31714L6.80267 11.6806C6.61005 11.8548 6.61005 12.1451 6.80267 12.3193L13.3411 18.6829" stroke="#2A2A2A" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
        </svg>
      )}
    </button>
  );
}

ButtonBack.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLandingPage: PropTypes.bool,
};
