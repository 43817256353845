import React, { useEffect } from 'react';

import { stub as $t } from '@nbcnews/analytics-framework';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useBedrockRegistration } from 'store';
import { SCREENS, SHOW_BACK_BUTTON } from 'lib/loginFormConstants';
// Components
import { LandingPageWrap } from 'components/AccountLoginRegistration/LandingPageWrap';
import { ButtonBack } from './Icons/ButtonBack';

// Screens
import { EmailScreen } from './Email';
import { LogInScreen } from './LogIn';
import { SuccessScreen } from './Success';
import { CreateAccountScreen } from './CreateAccount';
import { SocialRegistrationScreen } from './SocialRegistration';
import { VerifyOTCScreen } from './VerifyOneTimeCode';
import { UserNameScreen } from './UserName';
import { PoweredByNBCUProfile } from './PoweredByNBCUProfile';

import styles from './styles.module.scss';

$t('register', 'ramen_authflow');

/**
 * Loading component
 * @param {object} props - The props object.
 * @param {boolean} props.loading - The loading state.
 * @returns {React.ReactElement|null} The rendered component.
 */
function Loading({ loading }) {
  if (!loading) return null;
  return (<div data-testid="loading" className={styles.loader} />);
}

/**
 * Render select to Manually select screen
 * @param {object} props - The props object.
 * @param {LoginScreens} props.screen - The current screen.
 * @param {Function} props.setScreen - The setScreen function.
 * @returns {React.ReactElement|null} The rendered component.
 */
function ManualSelect({ screen, setScreen }) {
  const {
    query: { MANUAL_SCREEN: shouldShowManualScreen },
  } = useRouter();
  if (shouldShowManualScreen !== 'true') return null;
  /**
   * Manually sets the screen based on the selected value.
   * This is a temporary solution to bypass API calls.
   *
   * @param {object} e - The event object.
   */
  const manuallySetScreen = (e) => {
    setScreen(e.target.value);
  };

  return (
    <div className={styles.temp}>
      <p>Manually set screen to bypass API calls</p>
      <select onChange={manuallySetScreen} value={screen}>
        {Object.values(SCREENS).map((screenName) => (
          <option key={screenName} value={screenName}>
            {screenName}
          </option>
        ))}
      </select>
    </div>
  );
}

/**
 * Loading state after user has completed login/registration
 * @returns {React.ReactElement} The loading component
 */
const LoadingState = () => <Loading loading />;

/**
 * handles the screens for the login and registration flow
 *
 * @param {object} props - Component props.
 * @param {LoginLayout} [props.layout] - The layout for the account, can be 'fixed' or 'fluid'.
 * @param {string} [props.entryTitle] - Title for the entry screen.
 * @param {boolean} [props.showSuccessScreen] - Whether to show a screen for registration success.
 * @param {Function} [props.callback] - Callback function to be called when the login/registration process is done
 * and there are no screens to show.
 * @param {boolean} [props.isLandingPage] - To check whether the layout is Landing Page
 * @param {boolean} [props.isOverlay] - To check whether the layout is Overlay.
 * @returns {React.ReactElement} The rendered component.
 */
const AccountLoginRegistration = ({
  entryTitle = 'Sign up or log in with your free TODAY account!',
  layout = 'fixed',
  showSuccessScreen = false,
  callback = () => { },
  isLandingPage,
  isOverlay,
}) => {
  const loading = useBedrockRegistration((state) => state.loading);
  const screen = useBedrockRegistration((state) => state.screen);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const authState = useBedrockRegistration((state) => state.authenticationState);
  const userId = useBedrockRegistration((state) => state.user.user_id);

  let error = useBedrockRegistration((state) => state.error);

  /**
   * func to centralize tracking for auth flow
   * Tracks user authentication flow events with additional metadata.
   * @param {object} payload - The data to be tracked.
   * @param {string} payload.action - The action to be tracked.
   * @param {string} payload.method - The method to be tracked.
   * @returns {void}
   */
  const track = (payload) => {
    $t('track', 'ramen_authflow', {
      ...payload,
      userId,
      authState,
    });
  };
  if (error === 'Please check your user input and try again.') {
    error = 'Wrong password, please try again.';
  }
  useEffect(() => {
    if (error) {
      $t('track', 'error', {
        screen,
        error,
      });
    }
  }, [error]);
  /**
   * Returns the appropriate form view based on screen name status
   * @returns {React.Component} The screen component.
   */
  const getScreen = () => {
    switch (screen) {
      case SCREENS.LOGIN_PASSWORD:
        return LogInScreen;
      case SCREENS.CREATE_ACCOUNT:
        return CreateAccountScreen;
      case SCREENS.SOCIAL_REGISTRATION:
        return SocialRegistrationScreen;
      case SCREENS.VERIFY_OTC:
      case SCREENS.LOGIN_OTC:
        return VerifyOTCScreen;
      case SCREENS.REGISTRATION_SUCCESS:
        return showSuccessScreen
          ? SuccessScreen
          : setScreen(SCREENS.LOGIN_SUCCESS);
      case SCREENS.LOGIN_SUCCESS:
        return LoadingState;
      case SCREENS.NBCU_PROFILE:
        return PoweredByNBCUProfile;
      case SCREENS.USER_NAME:
        return UserNameScreen;
      default:
        return EmailScreen;
    }
  };

  const Screen = getScreen();

  /**
   * Handles the back button click event
   * @param {string} targetScreen - The target screen to navigate to
   * @returns {void}
   * */

  /**
   *
   * @param {string} targetScreen - the previous screen
   */
  const handleBackClick = (targetScreen) => {
    const payload = {
      action: 'Click:Button',
      value: 'BackButton',
      screen,
      targetScreen,
    };
    track(payload);
    setScreen(targetScreen);
  };

  /**
   * Handles back button visibility
   * @returns {React.ReactElement|null} The back button component or null
   */
  const back = () => {
    const targetScreen = SHOW_BACK_BUTTON[screen];
    return targetScreen ? (
      <ButtonBack
        isLandingPage={isLandingPage}
        onClick={() => handleBackClick(targetScreen)}
      />
    ) : null;
  };

  // Callback when there are no screens to show and the flow is complete
  useEffect(() => {
    if (screen === SCREENS.LOGIN_SUCCESS) callback();
  }, [screen]);

  /**
   * @param {object} props React props
   * @param {object} [props.children] React children for content of AccountLoginRegistration component
   * @returns {React.ReactElement} Empty wrapper component.
   */
  const NoWrap = ({ children }) => <>{children}</>;

  const ScreenWrap = isLandingPage ? LandingPageWrap : NoWrap;

  return Screen ? (
    <>
      <ManualSelect screen={screen} setScreen={setScreen} />
      <ScreenWrap>
        <div
          className={cx(styles.wrapper, styles[`wrapper__${layout}`])}
          data-testid="screen-wrapper"
          data-activiity-map="bedrock-login"
        >
          {!isLandingPage ? back() : null}
          <div className={styles.screen}>
            {isLandingPage ? back() : null}
            <Loading loading={loading} />
            <Screen
              isLandingPage={isLandingPage}
              entryTitle={entryTitle}
              isOverlay={isOverlay}
              track={track}
            />
            {error ? (
              <div className={cx(styles.errorMessage, styles.standaloneError)}>
                {error}
              </div>
            ) : null}
          </div>
        </div>
      </ScreenWrap>
    </>
  ) : null;
};
export { AccountLoginRegistration };
