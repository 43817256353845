import Ad from 'lib/Ads/Ad';
import MPS from 'lib/Ads/MPS';
import AdRegister from 'lib/Ads/AdRegister';
import { getAdOptionsFromElement } from 'lib/Ads/adUtils';


/**
 * Initializes the ad system by configuring MPS and processing ad elements.
 * @throws {Error} If window.__mpsconfig is not set.
 */
export function initAds() {
  // eslint-disable-next-line no-underscore-dangle
  window.__nbcnd__ = window.__nbcnd__ || {};

  // eslint-disable-next-line no-underscore-dangle
  window.__nbcnd__.adQueue = window.__nbcnd__.adQueue || [];

  /**
   * Creates an ad instance for the given element.
   * @param {HTMLElement} el - The element to create an ad for.
   * @returns {Ad} The initialized ad instance.
   */
  function createAd(el) {
    const initializedAd = AdRegister.findByElement(el);
    return initializedAd || new Ad(el, getAdOptionsFromElement(el));
  }

  /**
   * Executes a callback function with MPS and createAd as arguments.
   * @param {Function} fn - The callback function to execute.
   */
  function runCallback(fn) {
    fn(MPS, createAd);
  }

  if (!window.__mpsconfig) { // eslint-disable-line no-underscore-dangle
    console.error('window.__mpsconfig has not been set. set it or else.');
    return;
  }

  MPS.configure(window.__mpsconfig); // eslint-disable-line no-underscore-dangle
  Array.prototype.forEach.call(document.querySelectorAll('[data-mps]'), createAd);

  window.__nbcnd__.adQueue.forEach(runCallback); // eslint-disable-line no-underscore-dangle
  window.__nbcnd__.adQueue.length = 0; // eslint-disable-line no-underscore-dangle
  window.__nbcnd__.adQueue.push = runCallback; // eslint-disable-line no-underscore-dangle
}
