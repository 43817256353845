import PropTypes from 'prop-types';

export const articleFragment = PropTypes.shape({
  headline: PropTypes.shape({}),
  url: PropTypes.shape({}),
  id: PropTypes.string,
});

export const showBlogNavigation = PropTypes.shape({
  previous: articleFragment,
  next: articleFragment,
  latest: articleFragment,
});
