/* eslint-disable no-console */
import React from 'react';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import { Button } from 'components/Button';
import { SCREENS } from 'lib/loginFormConstants';
import {
  Formik, Field, ErrorMessage,
} from 'formik';

import globalStyles from '../styles.module.scss';

/**
 * NewUserScreen component for collecting user's first name.
 * @param {object} props - The component props.
 * @param {Function} props.track - The tracking function.
 * @returns {React.ReactElement} The rendered component.
 */
const UserNameScreen = ({ track }) => {
  const userProfileUpdate = useBedrockRegistration((state) => state.userProfileUpdate);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const currentScreen = useBedrockRegistration((state) => state.screen);
  const signInType = useBedrockRegistration((state) => state.signInType);
  /**
   * Handles form submission
   * @param {object} values - The component props.
   * @param {object} values.firstName - The one time code sent to the user.
   */
  const onSubmit = (values) => {
    userProfileUpdate({ first_name: values.firstName }).then((result) => {
      const payload = {
        action: 'SignUp:Start',
        method: `${currentScreen}:SubmitButton:Click`,
      };
      track(payload);
      // update the screen value based on the response
      if (result) {
        const successPayload = {
          action: 'SignUp:Success',
          method: `${currentScreen}:SubmitButton:Click`,
        };
        track(successPayload);
        setScreen(result);
      }
    });
  };

  /**
   * Handles resend code button click
   */
  const handleSkip = () => {
    const targetScreen = signInType === 'registration' ? SCREENS.REGISTRATION_SUCCESS : SCREENS.LOGIN_SUCCESS;
    const payload = {
      action: 'Click:Button',
      value: 'SkipButton',
      screen: currentScreen,
      targetScreen,
    };
    track(payload);
    setScreen(targetScreen);
  };

  return (
    <>
      <h2 data-testid="account-newUser">Congrats! You now have a TODAY account!</h2>
      <p className={globalStyles.label}>
        Time to get to know you
      </p>

      <Formik
        initialValues={{ firstName: '' }}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          errors,
        }) => (
          <form
            onSubmit={handleSubmit}
            data-testid="account-firstName"
            className={globalStyles.form}
          >
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="firstName" className={globalStyles.label}>What is your first name?</label>
            <Field
              name="firstName"
              type="input"
              placeholder=""
              data-testid="name-input-field"
              className={errors.firstName ? globalStyles.errorInput : ''}
            />
            <ErrorMessage name="firstName" component="div" className={globalStyles.errorMessage} />
            <Button
              displayType="submit"
              title="Continue"
              onClick={handleSubmit}
            />
          </form>

        )}
      </Formik>
      <button
        type="button"
        className={globalStyles.buttonLink}
        onClick={handleSkip}
        data-testid="skip-button"
      >
        Skip
      </button>
    </>

  );
};

export { UserNameScreen };
