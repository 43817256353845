import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import { RouteContext } from 'lib/ContextTypes';
import { children as ChildrenPropType } from 'lib/CustomPropTypes';
import getRelativePathBasedOnDomain from 'lib/getRelativePathBasedOnDomain';

/**
 * Create links
 * @param {import('react').AnchorHTMLAttributes<HTMLAnchorElement>} props
 * @param {String} props.domain
 * @param {ReactNode} props.children
 * @param {String} props.to
 * @param {String} props.href
 * @param {String} props.host
 * @param {String} props.className
 * @param {String} props.deviceUrl
 * @param {String} props.icid; we add this as a data attribute below
 */

const Link = ({
  children,
  className,
  deviceUrl,
  href,
  to,
  icid,
  stopPropagation = false,
  preventDefault = false,
  ...attribs
}) => {
  const route = React.useContext(RouteContext);

  const {
    publicRuntimeConfig: {
      ENABLE_RELATIVE_LINKS,
    },
  } = getConfig();

  // "to" property was used for SPA
  // consolidate with href now that SPA is deprecated
  let hyperlink = to || href;

  if (deviceUrl) {
    hyperlink = deviceUrl;
  } else if (ENABLE_RELATIVE_LINKS && !/^(mailto|sms|tel)/i.test(hyperlink)) {
    if (route.domain && route.hostname) {
      // use relative paths for environment based testing
      hyperlink = getRelativePathBasedOnDomain(hyperlink, route.domain, route.hostname)
        || hyperlink;
    }
  }


  const handleEventBubbling = preventDefault || stopPropagation;
  const eventBubbleHandler = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (preventDefault) {
      e.preventDefault();
    }
  };

  const linkAttributes = {
    href: hyperlink,
    ...(handleEventBubbling && { onClick: eventBubbleHandler }),
    ...attribs,
  };

  // if searchParams exists, we want to add that value as a data-icid attribute
  if (icid) {
    linkAttributes['data-icid'] = icid;
  }

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <a
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...linkAttributes}
      className={className}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  icid: PropTypes.string,
  children: ChildrenPropType,
  className: PropTypes.string,
  deviceUrl: PropTypes.string,
  domain: PropTypes.string,
  host: PropTypes.string,
  href: PropTypes.string,
  rel: PropTypes.string,
  to: PropTypes.string,
  stopPropagation: PropTypes.bool,
  preventDefault: PropTypes.bool,
};


export default Link;
