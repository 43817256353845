/* eslint-disable global-require */
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import rootReducer from './modules';

const {
  DISABLE_REDUX_LOGGING,
  NODE_ENV,
} = process.env;

// Inject final middleware at application runtime.
const bindMiddleware = (middleware) => {
  // Log redux in development environment
  if (NODE_ENV !== 'production' && DISABLE_REDUX_LOGGING === 'false') {
    const { composeWithDevTools } = require('@redux-devtools/extension');
    // Create custom logger object to set configuration.
    const { createLogger } = require('redux-logger');
    // redux-logger must be the final element in middleware array.
    middleware.push(createLogger({
      collapsed: true,
    }));

    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

// https://github.com/vercel/next.js/blob/canary/examples/with-redux-wrapper/store/store.js#L20
const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };

    return nextState;
  }
  return rootReducer(state, action);
};

const initStore = () => {
  const middleware = [thunkMiddleware];

  return createStore(reducer, bindMiddleware(middleware));
};

// export an assembled wrapper
// DEVS: Set debug to true for a very cluttered log of store initialization in your terminal.
export const wrapper = createWrapper(initStore, { debug: false });
