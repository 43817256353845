/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Reaptcha from 'reaptcha';

import { Button } from 'components/Button';
import { useBedrockRegistration } from 'store';
import { Formik, Field, ErrorMessage } from 'formik';
import { SocialLogin } from 'components/AccountLoginRegistration/SocialLogin';
import { Circles } from 'components/Gate/Circles';
import { SCREENS } from 'lib/loginFormConstants';
import { useVertical } from 'lib/Hooks';
import { pickSiteKey } from './verticalSitekeys';
import globalStyles from '../styles.module.scss';
import styles from './styles.module.scss';

import { emailSchema } from '../validationSchema';

/**
 * EmailScreen component for handling email input and submission.
 * @param {object} props - The component props.
 * @param {string} props.entryTitle - The title of the entry screen.
 * @param {boolean} props.isOverlay - To check whether the layout is Overlay.
 * @param {boolean} props.track - tracking funnction, takes payload of action and method
 * @returns {React.ReactElement} The rendered component.
 */
const EmailScreen = ({ entryTitle, isOverlay, track }) => {
  const showAuthenticationModal = useBedrockRegistration((state) => state.showAuthenticationModal);
  const setEmail = useBedrockRegistration((state) => state.setEmail);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const { email } = useBedrockRegistration((state) => state.user);
  const searchUserByEmail = useBedrockRegistration((state) => state.searchUserByEmail);
  const setError = useBedrockRegistration((state) => state.setError);
  const [captcha, setCaptcha] = useState(null);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const { vertical } = useVertical();
  const siteKey = pickSiteKey(vertical);
  const inputRef = useRef();

  useEffect(() => {
    if (showAuthenticationModal) {
      inputRef.current.focus();
    }
  }, []);

  /**
   * Handles response from searchUserByEmail when captcha is verified.
   */
  const captchaVerify = async () => {
    const response = await searchUserByEmail(email, 'today');
    if (response.success) {
      setScreen(SCREENS.LOGIN_PASSWORD);
    } else {
      setScreen(SCREENS.CREATE_ACCOUNT);
    }
  };

  /**
   * Handles loading of the Reaptcha component.
   */
  const onLoad = () => {
    setCaptchaLoaded(true);
  };
  return (
    <>
      <div className={styles.content}>
        <h2 className={styles.title}>{entryTitle}</h2>
        <Formik
          initialValues={{ email }}
          validationSchema={emailSchema}
          onSubmit={async (values, { setSubmitting }) => {
            if (captcha && captchaLoaded) {
              setEmail(values.email);
              captcha.execute();
              track({
                action: 'SignUp:Start',
                method: 'EmailScreen:SubmitButton:Click',
              });
            } else {
              setError('There was an error submitting your email. Please try again.');
            }
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            errors,
          }) => (
            <form onSubmit={handleSubmit} data-testid="account-email" className={globalStyles.form}>
              <label htmlFor="email" className={globalStyles.label}>Please enter your email.</label>
              <Reaptcha
                ref={(instance) => {
                  setCaptcha(instance);
                }}
                sitekey={siteKey}
                onVerify={captchaVerify}
                onLoad={onLoad}
                size="invisible"
              />
              <Field
                id="email"
                type="email"
                name="email"
                aria-label="email"
                className={errors.email ? globalStyles.errorInput : ''}
                innerRef={inputRef}
              />
              <ErrorMessage name="email">{(msg) => <div className={cx(styles.error, globalStyles.errorMessage, globalStyles.errorMessageEmail)}>{msg}</div>}</ErrorMessage>
              <Button
                tiltClasses={globalStyles.button}
                displayType="submit"
                title="Continue"
                disabled={isSubmitting}
              />
              <div className={styles.separator}><span>or</span></div>
            </form>
          )}
        </Formik>
        <div className={styles.bottom}>
          {isOverlay || !showAuthenticationModal ? <SocialLogin track={track} /> : null}
          <button
            type="button"
            className={globalStyles.buttonLink}
            onClick={() => {
              track({
                action: 'SignUp:Start',
                method: 'SignUpModal:PoweredByNBCU',
              });
              setScreen(SCREENS.NBCU_PROFILE);
            }}
            data-testid="nbcu-profile-button"
          >
            Powered by NBCUniversal Profile
          </button>
        </div>
      </div>
      <div className={cx(styles.circleWrapper, styles.circleLeft)}>
        <Circles />
      </div>
      <div className={cx(styles.circleWrapper, styles.circleRight)}>
        <Circles />
      </div>
    </>
  );
};

EmailScreen.propTypes = {
  entryTitle: PropTypes.string.isRequired,
};

export { EmailScreen };
